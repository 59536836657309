.contactForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
  &__input {
    &-container {
      display: flex;
      gap: 1.88rem;
      justify-content: space-between;
      align-items: center;
      width: 29.5rem;
      @media (max-width: $breakpoint-tablet) {
        flex-direction: column;
        width: 80%;
      }
    }
    border-radius: 0.625rem;
    border: 1px solid #000;
    background: #fdfdfd;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    width: 22.0625rem;
    height: 1.8125rem;
    flex-shrink: 0;
    padding-left: 1em;
    &-large {
      min-height: 8em;
    }
  }
}
