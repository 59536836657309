.imageCarousel {
  background-color: none;
  border-radius: 25px;
  width: 30rem;
  aspect-ratio: 30 / 38;
  @media (max-width: $breakpoint-mobile) {
    width: 335px;
    height: 435px;
  }
  &__controlsContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  &__next,
  &__prev {
    padding: 1em;
    cursor: pointer;
    width: 30px;
    height: 30px;
    @media (max-width: $breakpoint-mobile) {
      width: 12px;
      height: 10px;
    }
    &Text {
      height: 20px;
    }
  }
  &__counter {
    color: $colour-text-inverse;
    font-size: 1rem;
    align-self: flex-end;
    margin-bottom: 2em;
  }
}
