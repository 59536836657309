.maintenance{
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-family: $ff-primary;
  max-height: 100vh;
  background-color: $colour-primary-light;
  &__logo{
    max-height: 60vh;
    aspect-ratio: 1/1;
  }
  &__text{
    max-width: 60%;
  }
}