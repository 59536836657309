//Colour Palette Monochrome
$colour-primary: rgb(72, 4, 148);
$colour-primary-10: #4704941f;
$colour-primary-20: #47049434;
$colour-primary-40: #47049464;
$colour-primary-light: #e9e0f2;

$colour-secondary: #161616;
$colour-secondary-20: #16161637;
$colour-accent: #a520e1;
$colour-accent-light: #c85afa;
$colour-text: #1f1f1f;
$colour-text-inverse: #e5e7f0;

//Breakpoints
$breakpoint-tablet: 1440px;
$breakpoint-mobile: 767.98px;

//Box Shadows

$bs-colour-accent: 0 0 5px $colour-accent;

//Typography

@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');
$ff-primary: 'Josefin Sans';

@import url('https://fonts.googleapis.com/css?family=Poppins:700|Poppins:400');

body {
  font-family: 'Josefin Sans';
  font-weight: 400;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: 'Josefin Sans';
  font-weight: 700;
  font-style: normal;
}

html {
  font-size: 100%;
} /* 16px */

h1 {
  font-size: 4.21rem; /* 67.36px */
}

$fs-h1: 4.21rem;
$fs-mobile-h1: 2.369rem;

h2 {
  font-size: 2.75rem; /* 44px */
}

$fs-h2: 2.75rem;
$fs-mobile-h2: 1.777rem;

h3 {
  font-size: 2.369rem; /* 37.92px */
}

$fs-h3: 2.369rem;
$fs-mobile-h3: 1.333rem;

h4 {
  font-size: 1.777rem; /* 28.48px */
}

$fs-h4: 1.777rem;
$fs-mobile-h4: 1.333rem;

h5 {
  font-size: 1.333rem; /* 21.28px */
}

$fs-h5: 1.333rem;
$fs-mobile-h5: 0.75rem;

small {
  font-size: 0.75rem; /* 12px */
}

@mixin glassEffect {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(3.9px);
  -webkit-backdrop-filter: blur(3.9px);
}

@media (max-width: $breakpoint-mobile) {
  h1 {
    font-size: $fs-mobile-h1;
  }
  h2 {
    font-size: $fs-mobile-h2;
  }
  h3 {
    font-size: $fs-mobile-h3;
  }
  h4 {
    font-size: $fs-mobile-h4;
  }
  h5 {
    font-size: $fs-mobile-h5;
  }
  .body {
    font-size: 16px;
  }
}
