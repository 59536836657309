.pcDetails {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  &__hero {
    width: 100vw;
    display: flex;
    gap: 6.3rem;
    align-items: center;
    justify-content: center;
    min-height: 80vh;
    @media (max-width: $breakpoint-mobile) {
      flex-direction: column;
      gap: 2em;
      margin: 2em 0;
      text-align: center;
    }
    &-text {
      @media (max-width: $breakpoint-mobile) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }

    &-pcName {
      font-size: 2.75rem;
      font-weight: 400;
      margin: 1.5rem 0;
    }
    &-pcFullName {
      font-size: 2rem;
      font-weight: 400;
      margin: 0;
    }
    &-pcPrice {
      font-size: 1.3125rem;
      font-weight: 400;
      margin: 3rem 0 1.5rem 0;
    }
    &-pcBuy {
      width: 5.1875rem;
      height: 2.43369rem;
      border-radius: 1.25rem;
      color: $colour-text-inverse;
      font-size: 1.3125rem;
    }
  }
  &__specsBanner {
    height: 30vh;
    display: flex;
    align-items: center;
    justify-content: center;
    &-title {
      font-family: 'Josefin Sans';
      font-size: 2.75rem;
      font-weight: 400;
      text-decoration-line: underline;
    }
  }
  &__specs {
    display: grid;
    margin: 3em auto;
    width: 90%;
    justify-items: center;
    grid-template-columns: repeat(auto-fit, minmax(440px, 3fr));
    gap: 4em 2em;
    @media (max-width: $breakpoint-mobile) {
      display: flex;
      flex-direction: column;
    }
    &-container {
      display: flex;
      align-items: center;
      gap: 1.3em;
      width: 100%;
      @media (max-width: $breakpoint-mobile) {
        flex-direction: column;
        text-align: center;
      }
    }
    &-text {
      width: 60%;
    }
    &-image {
      width: 9.3rem;
      height: 9.3rem;
    }
    &-title {
      font-size: 2rem;
      font-weight: 400;
      margin: 0.5em 0;
    }
    &-data {
      font-family: 'Josefin Sans';
      font-size: 1.3125rem;
      font-weight: 400;
      margin: 0;
    }
  }
}
