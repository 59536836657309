.pcCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: $colour-primary-40;
  width: 22.375rem;
  height: 43.125rem;
  border-radius: 2.5rem;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  @media (max-width: $breakpoint-mobile) {
    width: 18.375rem;
  }
  &-sold {
    background-color: $colour-primary-20;
  }
  &__category {
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  &__image {
    width: 15.4375rem;
    height: 17.5rem;
    border-radius: 2.5rem;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    background: none;
  }
  &__topText {
    &-Container {
      text-align: center;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    &-name {
      font-size: 1.3125rem;
      font-weight: 400;
      margin: 1.81rem 0 0.62rem 0;
    }
    &-fullName {
      font-size: 1.3125rem;
      font-weight: 400;
      margin: 0;
    }
    &-price,
    &-salePrice {
      margin: 0;
    }
    &-price {
      &--sale {
        text-decoration: line-through;
        margin: 0;
      }
    }
  }
  &__price {
    &-container {
      display: flex;
      justify-content: space-evenly;
      font-size: 1.3125rem;
      font-weight: 700;
      width: 70%;
      margin: 1.25rem 0 2.19rem 0;
    }
  }
  &__details {
    margin: 0;
    width: 85%;
  }
  &__buttons {
    &-container {
      width: 85%;
    }
    &-forSale {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &-sold {
      display: flex;
      justify-content: center;
    }
    &-seeDetails {
      border-radius: 1.25rem;
      background: rgba(22, 22, 22, 0.9);
      width: 8.24756rem;
      height: 2.6875rem;
      text-align: center;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      color: #fffefe;
      font-family: 'Josefin Sans';
      font-size: 1.3125rem;
      font-weight: 400;
      transition: all 200ms ease-in-out;
      &:hover,
      &:active {
        transform: translateY(-3px);
        background: $colour-accent;
        box-shadow: $bs-colour-accent;
        color: white;
      }
    }
    &-buy {
      border-radius: 1.25rem;
      background: #480494;
      border: none;
      width: 4.83775rem;
      height: 2.6875rem;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fffefe;
      text-align: center;
      font-size: 1.3125rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      cursor: pointer;
      transition: all 200ms ease-in-out;
      &:hover,
      &:active {
        transform: translateY(-3px);
        background: $colour-accent;
        box-shadow: $bs-colour-accent;
        color: white;
      }
    }
  }
}
