.addPC {
  min-height: 90vh;
  margin: 4em 0;
}

.newPCForm {
  width: 100%;
  overflow: hidden;
  &__row {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 6.3rem;
    @media (max-width: $breakpoint-mobile) {
      flex-direction: column;
      align-items: center;
    }
    &-last {
      width: 30%;
      margin: 2em auto;
      align-items: center;
      @media (max-width: $breakpoint-mobile) {
        flex-direction: row;
        gap: 2em;
      }
    }
  }
  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.44rem;
    @media (max-width: $breakpoint-mobile) {
      width: 100%;
      margin: 2em 0;
    }
  }
  &__subtitle {
    color: #000;
    text-align: center;
    font-family: 'Josefin Sans';
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    &-fulldescript {
      margin-bottom: 3rem;
    }
  }
  &__input {
    &-container {
      display: flex;
      gap: 1.88rem;
      justify-content: flex-end;
      align-items: center;
      width: 29.5rem;
      @media (max-width: $breakpoint-tablet) {
        flex-direction: column;
        width: 80%;
      }
    }
    border-radius: 0.625rem;
    border: 1px solid #000;
    background: #fdfdfd;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    width: 22.0625rem;
    height: 1.8125rem;
    flex-shrink: 0;
    padding-left: 1em;
    @media (max-width: $breakpoint-mobile) {
      width: 80%;
    }
  }
  &__image-container {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 2.5rem;
    background: #e8f1f6;
    width: 420px;
    height: 169px;
    flex-shrink: 0;
    margin-bottom: 1em;
    @media (max-width: $breakpoint-mobile) {
      width: 80%;
    }
  }
  &__coverImage {
    &-Preview {
      position: absolute;
      top: 0;
      bottom: 0;
      height: 100%;
      opacity: 0;
      z-index: 2;
    }
    &-AddButton {
      width: 173px;
      height: 36px;
      border-radius: 50px;
      border: none;
      background: #cbd6dc;
      color: #306685;
      text-align: center;
      font-family: Work Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin: 1em;
    }
    &::file-selector-button {
      opacity: 0;
    }
    &-AddRules {
      display: flex;
      color: #444;
      text-align: center;
      font-family: Work Sans;
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    &-input {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      width: 100%;
      height: 100%;
      z-index: 3;
      &:hover,
      &:active {
        cursor: pointer;
      }
    }
  }
  &__sold {
    &-label {
      font-size: 1.3125rem;
    }
    &-container {
      display: flex;
      gap: 0.63rem;
      align-items: center;
    }
  }
  &__submit {
    font-size: 1.3125rem;
  }
}

input[type='checkbox'] {
  display: grid;
  place-content: center;
  appearance: none;
  background-color: #d9d9d9;
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 1.15em;
  height: 1.15em;
  border-radius: 0.15em;
  transform: translateY(-0.075em);
  cursor: pointer;
  &::before {
    content: '';
    width: 0.65em;
    height: 0.65em;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em $colour-primary;
  }
  &:checked::before {
    transform: scale(1);
  }
}

.updatePC {
  &__feature {
    margin-top: 2em;
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  &__deleteModal {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    &-button {
      &-container {
        display: flex;
        gap: 1em;
      }
    }
  }
}
