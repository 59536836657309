.dropdownmenu {
  padding: 1em 1em 1em 1em;
  height: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: $breakpoint-mobile) {
    padding: 0.25em;
  }
}

.dropdown {
  background-color: $colour-secondary-20;
  padding: 1em 2em 2em 2em;
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1em;
  &__link {
    color: white;
    text-decoration: none;
    list-style: none;
    margin: 2em 0;
    cursor: pointer;
    transition: color 500ms ease-in-out;
    &:hover,
    &:active {
      color: $colour-accent;
    }
  }
  &__button {
    background: none;
    color: $colour-primary-40;
    font-size: $fs-h5;
    border: none;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-self: center;
    cursor: pointer;
    @media (max-width: $breakpoint-mobile) {
      font-size: 1rem;
    }
  }
  &__arrow {
    color: $colour-primary-40;
    margin: 0 0.25em;
  }
}
