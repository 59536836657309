.CTAButton {
  border-radius: 1.25rem;
  background: $colour-primary;
  color: $colour-text-inverse;
  padding: 0.6em 1.25em;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-family: 'Josefin Sans';
  border: none;
  cursor: pointer;
  transition: all 200ms ease-in-out;
  &:hover,
  &:active {
    transform: translateY(-3px);
    background: $colour-accent;
    box-shadow: $bs-colour-accent;
    color: white;
  }
  &--danger {
    background: rgb(145, 1, 1);
    color: white;
    align-self: center;
    &:hover,
    &:active {
      transform: translateY(-3px);
      background: rgb(216, 3, 3);
      box-shadow: rgb(216, 3, 3);
    }
  }
}
