.shop {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  &__hero {
    height: 60vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    position: relative;
    &-title {
      position: absolute;
      margin-bottom: 1em;
      font-size: 2.75rem;
      font-weight: 400;
      top: 60%;
      @media (max-width: $breakpoint-mobile) {
        text-align: center;
      }
    }
  }

  &__forSale {
    &-headerContainer {
      width: 90%;
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      margin-top: 2em;
    }
    &-title {
      color: rgba(72, 4, 148, 0.45);
      font-size: 2rem;
      font-weight: 400;
      @media (max-width: $breakpoint-mobile) {
        font-size: $fs-mobile-h4;
      }
    }
    &-decobar {
      border: solid rgba(72, 4, 148, 0.05) 1px;
      width: 70%;
      height: 0px;
    }
    &-pcList {
      width: 80%;
      display: grid;
      justify-items: center;
      grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));
      gap: 4em 2em;
      margin-bottom: 2em;
      @media (max-width: $breakpoint-mobile) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        align-items: center;
      }
    }
    &-filters {
      &-button {
        background: $colour-primary-20;
        color: white;
        cursor: pointer;
        border: none;
        border-radius: 20px;
        padding: 0.5em;
        &--active {
          background: $colour-primary-40;
        }
      }
      @media (max-width: $breakpoint-mobile) {
        max-width: 30%;
      }
    }
  }
}
