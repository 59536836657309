.login {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
  &__container {
    background: $colour-secondary;
    width: 40%;
    height: 40vh;
    border-radius: 2.5rem;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1em;
  }
  &__input {
    @media (max-width: $breakpoint-mobile) {
      max-width: 80%;
    }
  }
  &__label {
    color: $colour-text-inverse;
  }
}
