.navbar {
  height: 4.25rem;
  width: 100vw;
  background-color: $colour-primary-light;
  display: flex;
  justify-content: space-evenly;
  position: sticky;
  top: 0px;
  z-index: 10;
  overflow: hidden;
  @media (max-width: $breakpoint-mobile) {
  }
  &__links-left,
  &__links-right {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 50%;
    @media (max-width: $breakpoint-mobile) {
      flex-direction: column;
    }
  }
  &__link {
    color: $colour-text;
    text-decoration: none;
    position: relative;
    font-family: $ff-primary;
    font-size: 1rem;
    @media (max-width: $breakpoint-mobile) {
      font-size: $fs-mobile-h5;
    }
    &1 {
      background: none;
      border: none;
    }
    &:hover,
    &:active {
      cursor: pointer;
    }
    &::before {
      content: '';
      position: absolute;
      bottom: 2em;
      top: 1.25em;
      left: 1em;
      right: 1em;
      border: solid 1.5px $colour-text;
      transform: scale(0, 1);
      transition: transform ease-out 250ms;
    }
    &:hover::before {
      transform: scale(1.4, 1);
    }
  }
  &__logo {
    &-image {
      transition: all ease-in-out 400ms;
      width: 22.8rem;
      position: relative;
      top: 60%;
      &--reduced {
        position: relative;
        top: 0;
        max-width: 5rem;
        height: 100%;
      }
      @media (max-width: $breakpoint-mobile) {
        max-width: 5rem;
        align-self: center;
        justify-self: center;
        top: 0;
      }
    }
  }
}
