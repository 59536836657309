.footer {
  background-color: black;
  color: $colour-text-inverse;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 40vh;
  padding-top: 4em;
  overflow: hidden;
  &__title {
    display: flex;
    &-link {
      color: $colour-text-inverse;
      text-decoration: none;
    }
  }
  &__content {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    margin: 2em 0;
    @media (max-width: $breakpoint-mobile) {
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    &-left,
    &-right {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      width: 100%;
      @media (max-width: $breakpoint-mobile) {
        justify-content: space-evenly;
        align-items: center;
        text-align: center;
      }
    }
  }
  &__text {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $colour-text-inverse;
    text-decoration: none;
    @media (max-width: $breakpoint-mobile) {
      text-align: center;
      width: 30%;
    }
  }
  &__address {
    width: 35%;
    text-align: center;
  }
  &__bottom {
    display: flex;
    justify-content: space-between;
    width: 95%;
    padding: 1em 1em 0 1em;
    color: grey;
    justify-self: flex-end;
    &-text {
      @media (max-width: $breakpoint-mobile) {
        text-align: center;
        width: 30%;
      }
    }
  }
}
