.reviewCard {
  width: 20.125rem;
  height: 27.8125rem;
  flex-shrink: 0;
  background: #d9d9d9;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: $breakpoint-tablet) {
    width: 80%;
    max-width: 20.125rem;
  }
  &__image {
    width: 8.4375rem;
    height: 8.4375rem;
    border-radius: 50%;
    margin: 3.13rem 0 1.75rem 0;
  }
  &__stars-container {
    margin: 0;
  }
  &__name {
    margin: 1rem 0;
    width: 9.75rem;
    height: 1.1875rem;
    flex-shrink: 0;
    color: #000;
    text-align: center;
    font-family: 'Josefin Sans';
    font-size: 1.3125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  &__text {
    margin: 0;
    color: #000;
    text-align: center;
    font-family: 'Josefin Sans';
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    max-width: 60%;
  }
  &__link {
    color: rgba(72, 4, 148, 0.8);
    text-align: center;
    font-family: 'Josefin Sans';
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration-line: underline;
    margin: 1.75rem 0 3.75rem 0;
  }
}
