@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap");
@import url("https://fonts.googleapis.com/css?family=Poppins:700|Poppins:400");
body {
  font-family: "Josefin Sans";
  font-weight: 400;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "Josefin Sans";
  font-weight: 700;
  font-style: normal;
}

html {
  font-size: 100%;
} /* 16px */
h1 {
  font-size: 4.21rem; /* 67.36px */
}

h2 {
  font-size: 2.75rem; /* 44px */
}

h3 {
  font-size: 2.369rem; /* 37.92px */
}

h4 {
  font-size: 1.777rem; /* 28.48px */
}

h5 {
  font-size: 1.333rem; /* 21.28px */
}

small {
  font-size: 0.75rem; /* 12px */
}

@media (max-width: 767.98px) {
  h1 {
    font-size: 2.369rem;
  }
  h2 {
    font-size: 1.777rem;
  }
  h3 {
    font-size: 1.333rem;
  }
  h4 {
    font-size: 1.333rem;
  }
  h5 {
    font-size: 0.75rem;
  }
  .body {
    font-size: 16px;
  }
}
html,
body {
  margin: 0;
  padding: 0;
}

.CTAButton {
  border-radius: 1.25rem;
  background: rgb(72, 4, 148);
  color: #e5e7f0;
  padding: 0.6em 1.25em;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-family: "Josefin Sans";
  border: none;
  cursor: pointer;
  transition: all 200ms ease-in-out;
}
.CTAButton:hover, .CTAButton:active {
  transform: translateY(-3px);
  background: #a520e1;
  box-shadow: 0 0 5px #a520e1;
  color: white;
}
.CTAButton--danger {
  background: rgb(145, 1, 1);
  color: white;
  align-self: center;
}
.CTAButton--danger:hover, .CTAButton--danger:active {
  transform: translateY(-3px);
  background: rgb(216, 3, 3);
  box-shadow: rgb(216, 3, 3);
}

.pcCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgba(71, 4, 148, 0.3921568627);
  width: 22.375rem;
  height: 43.125rem;
  border-radius: 2.5rem;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
@media (max-width: 767.98px) {
  .pcCard {
    width: 18.375rem;
  }
}
.pcCard-sold {
  background-color: rgba(71, 4, 148, 0.2039215686);
}
.pcCard__category {
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.pcCard__image {
  width: 15.4375rem;
  height: 17.5rem;
  border-radius: 2.5rem;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  background: none;
}
.pcCard__topText-Container {
  text-align: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.pcCard__topText-name {
  font-size: 1.3125rem;
  font-weight: 400;
  margin: 1.81rem 0 0.62rem 0;
}
.pcCard__topText-fullName {
  font-size: 1.3125rem;
  font-weight: 400;
  margin: 0;
}
.pcCard__topText-price, .pcCard__topText-salePrice {
  margin: 0;
}
.pcCard__topText-price--sale {
  text-decoration: line-through;
  margin: 0;
}
.pcCard__price-container {
  display: flex;
  justify-content: space-evenly;
  font-size: 1.3125rem;
  font-weight: 700;
  width: 70%;
  margin: 1.25rem 0 2.19rem 0;
}
.pcCard__details {
  margin: 0;
  width: 85%;
}
.pcCard__buttons-container {
  width: 85%;
}
.pcCard__buttons-forSale {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.pcCard__buttons-sold {
  display: flex;
  justify-content: center;
}
.pcCard__buttons-seeDetails {
  border-radius: 1.25rem;
  background: rgba(22, 22, 22, 0.9);
  width: 8.24756rem;
  height: 2.6875rem;
  text-align: center;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: #fffefe;
  font-family: "Josefin Sans";
  font-size: 1.3125rem;
  font-weight: 400;
  transition: all 200ms ease-in-out;
}
.pcCard__buttons-seeDetails:hover, .pcCard__buttons-seeDetails:active {
  transform: translateY(-3px);
  background: #a520e1;
  box-shadow: 0 0 5px #a520e1;
  color: white;
}
.pcCard__buttons-buy {
  border-radius: 1.25rem;
  background: #480494;
  border: none;
  width: 4.83775rem;
  height: 2.6875rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fffefe;
  text-align: center;
  font-size: 1.3125rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
  transition: all 200ms ease-in-out;
}
.pcCard__buttons-buy:hover, .pcCard__buttons-buy:active {
  transform: translateY(-3px);
  background: #a520e1;
  box-shadow: 0 0 5px #a520e1;
  color: white;
}

.imageCarousel {
  background-color: none;
  border-radius: 25px;
  width: 30rem;
  aspect-ratio: 30/38;
}
@media (max-width: 767.98px) {
  .imageCarousel {
    width: 335px;
    height: 435px;
  }
}
.imageCarousel__controlsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
}
.imageCarousel__next, .imageCarousel__prev {
  padding: 1em;
  cursor: pointer;
  width: 30px;
  height: 30px;
}
@media (max-width: 767.98px) {
  .imageCarousel__next, .imageCarousel__prev {
    width: 12px;
    height: 10px;
  }
}
.imageCarousel__nextText, .imageCarousel__prevText {
  height: 20px;
}
.imageCarousel__counter {
  color: #e5e7f0;
  font-size: 1rem;
  align-self: flex-end;
  margin-bottom: 2em;
}

.dropdownmenu {
  padding: 1em 1em 1em 1em;
  height: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (max-width: 767.98px) {
  .dropdownmenu {
    padding: 0.25em;
  }
}

.dropdown {
  background-color: rgba(22, 22, 22, 0.2156862745);
  padding: 1em 2em 2em 2em;
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1em;
}
.dropdown__link {
  color: white;
  text-decoration: none;
  list-style: none;
  margin: 2em 0;
  cursor: pointer;
  transition: color 500ms ease-in-out;
}
.dropdown__link:hover, .dropdown__link:active {
  color: #a520e1;
}
.dropdown__button {
  background: none;
  color: rgba(71, 4, 148, 0.3921568627);
  font-size: 1.333rem;
  border: none;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-self: center;
  cursor: pointer;
}
@media (max-width: 767.98px) {
  .dropdown__button {
    font-size: 1rem;
  }
}
.dropdown__arrow {
  color: rgba(71, 4, 148, 0.3921568627);
  margin: 0 0.25em;
}

.reviewCard {
  width: 20.125rem;
  height: 27.8125rem;
  flex-shrink: 0;
  background: #d9d9d9;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (max-width: 1440px) {
  .reviewCard {
    width: 80%;
    max-width: 20.125rem;
  }
}
.reviewCard__image {
  width: 8.4375rem;
  height: 8.4375rem;
  border-radius: 50%;
  margin: 3.13rem 0 1.75rem 0;
}
.reviewCard__stars-container {
  margin: 0;
}
.reviewCard__name {
  margin: 1rem 0;
  width: 9.75rem;
  height: 1.1875rem;
  flex-shrink: 0;
  color: #000;
  text-align: center;
  font-family: "Josefin Sans";
  font-size: 1.3125rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.reviewCard__text {
  margin: 0;
  color: #000;
  text-align: center;
  font-family: "Josefin Sans";
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  max-width: 60%;
}
.reviewCard__link {
  color: rgba(72, 4, 148, 0.8);
  text-align: center;
  font-family: "Josefin Sans";
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
  margin: 1.75rem 0 3.75rem 0;
}

.footer {
  background-color: black;
  color: #e5e7f0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 40vh;
  padding-top: 4em;
  overflow: hidden;
}
.footer__title {
  display: flex;
}
.footer__title-link {
  color: #e5e7f0;
  text-decoration: none;
}
.footer__content {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  margin: 2em 0;
}
@media (max-width: 767.98px) {
  .footer__content {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
.footer__content-left, .footer__content-right {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
}
@media (max-width: 767.98px) {
  .footer__content-left, .footer__content-right {
    justify-content: space-evenly;
    align-items: center;
    text-align: center;
  }
}
.footer__text {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #e5e7f0;
  text-decoration: none;
}
@media (max-width: 767.98px) {
  .footer__text {
    text-align: center;
    width: 30%;
  }
}
.footer__address {
  width: 35%;
  text-align: center;
}
.footer__bottom {
  display: flex;
  justify-content: space-between;
  width: 95%;
  padding: 1em 1em 0 1em;
  color: grey;
  justify-self: flex-end;
}
@media (max-width: 767.98px) {
  .footer__bottom-text {
    text-align: center;
    width: 30%;
  }
}

.navbar {
  height: 4.25rem;
  width: 100vw;
  background-color: #e9e0f2;
  display: flex;
  justify-content: space-evenly;
  position: sticky;
  top: 0px;
  z-index: 10;
  overflow: hidden;
}
.navbar__links-left, .navbar__links-right {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 50%;
}
@media (max-width: 767.98px) {
  .navbar__links-left, .navbar__links-right {
    flex-direction: column;
  }
}
.navbar__link {
  color: #1f1f1f;
  text-decoration: none;
  position: relative;
  font-family: "Josefin Sans";
  font-size: 1rem;
}
@media (max-width: 767.98px) {
  .navbar__link {
    font-size: 0.75rem;
  }
}
.navbar__link1 {
  background: none;
  border: none;
}
.navbar__link:hover, .navbar__link:active {
  cursor: pointer;
}
.navbar__link::before {
  content: "";
  position: absolute;
  bottom: 2em;
  top: 1.25em;
  left: 1em;
  right: 1em;
  border: solid 1.5px #1f1f1f;
  transform: scale(0, 1);
  transition: transform ease-out 250ms;
}
.navbar__link:hover::before {
  transform: scale(1.4, 1);
}
.navbar__logo-image {
  transition: all ease-in-out 400ms;
  width: 22.8rem;
  position: relative;
  top: 60%;
}
.navbar__logo-image--reduced {
  position: relative;
  top: 0;
  max-width: 5rem;
  height: 100%;
}
@media (max-width: 767.98px) {
  .navbar__logo-image {
    max-width: 5rem;
    align-self: center;
    justify-self: center;
    top: 0;
  }
}

.wrapper {
  width: 150px;
  height: 150px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 30px;
}

.item {
  width: inherit;
  height: inherit;
  background: white;
  transform-origin: 50% 100%;
}

.modal {
  background-color: #161616;
  position: fixed;
  width: 40%;
  height: 50%;
  border-radius: 20px;
  z-index: 9;
  overflow: scroll;
  padding: 2em;
  color: #e5e7f0;
}
@media (max-width: 1440px) {
  .modal {
    width: 80%;
    height: 70%;
  }
}
.modal::-webkit-scrollbar {
  display: none;
}
.modalClose {
  position: sticky;
  margin-top: 2em;
  left: 95%;
  outline: 0px;
  border: 0px;
  margin: 10px;
  cursor: pointer;
  line-height: 1.75;
  min-width: 64px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: #1f1f1f;
  border-radius: 12px;
  padding: 6px 16px;
  background-color: #161616;
}
.modalClose:hover, .modalClose:focus {
  cursor: pointer;
  background-color: #a520e1;
  box-shadow: 0 0 5px #a520e1;
}
.modal__title {
  text-align: center;
}

.Overlay {
  display: flex;
  position: fixed;
  background-color: purple;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(3.9px);
  -webkit-backdrop-filter: blur(3.9px);
  top: 0;
  z-index: 10;
  width: 100vw;
  height: 100vh;
}

.projectModal {
  display: flex;
  flex-direction: column;
  color: #1f1f1f;
  padding: 16px;
}
.projectModal__image {
  width: 49%;
}
.projectModal__firstContainer {
  display: flex;
  gap: 2em;
}
.projectModal__secondContainer {
  width: 100%;
  display: grid;
  justify-items: center;
  align-items: center;
  grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));
  gap: 2em 0.5em;
  overflow: hidden;
  align-self: flex-start;
  margin: 1em 1em;
}
.projectModal__exampleImages {
  width: 90%;
  transition: scale 200ms ease-in-out;
}
.projectModal__exampleImages:hover, .projectModal__exampleImages:focus {
  position: absolute;
  z-index: 11;
  transform: translateY(50px);
  scale: 0.7;
}

.contactForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
}
.contactForm__input {
  border-radius: 0.625rem;
  border: 1px solid #000;
  background: #fdfdfd;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  width: 22.0625rem;
  height: 1.8125rem;
  flex-shrink: 0;
  padding-left: 1em;
}
.contactForm__input-container {
  display: flex;
  gap: 1.88rem;
  justify-content: space-between;
  align-items: center;
  width: 29.5rem;
}
@media (max-width: 1440px) {
  .contactForm__input-container {
    flex-direction: column;
    width: 80%;
  }
}
.contactForm__input-large {
  min-height: 8em;
}

.homepage {
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 0;
}
@media (max-width: 767.98px) {
  .homepage {
    overflow: hidden;
  }
}
.homepage__hero {
  height: 100vh;
  min-width: 100vw;
  background: #161616;
  overflow: hidden;
}
@media (max-width: 767.98px) {
  .homepage__hero {
    min-height: 100%;
    overflow: hidden;
  }
}
.homepage__hero-video {
  min-width: 100%;
  overflow: hidden;
  object-fit: cover;
}
@media (max-width: 767.98px) {
  .homepage__hero-video {
    min-width: 0;
  }
}
.homepage__featured {
  height: 100vh;
  overflow: hidden;
}
.homepage__featured-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.homepage__featured-new {
  margin: 4.94rem 0 3.94rem 0;
}
.homepage__featured-title {
  margin: 2rem 0 2.25rem 0;
}
.homepage__featured-learnmore {
  margin-top: 0.69rem;
  transition: transform 200ms ease-in-out;
}
.homepage__featured-learnmore:hover, .homepage__featured-learnmore:active {
  transform: translateY(-3px);
  color: #a520e1;
}
.homepage__featured-buy {
  transition: all 200ms ease-in-out;
  font-size: 1rem;
}
.homepage__featured-buy:hover, .homepage__featured-buy:active {
  transform: translateY(-3px);
  background: #a520e1;
  box-shadow: 0 0 5px #a520e1;
  color: white;
}
.homepage__refurbished {
  background: #161616;
  z-index: 5;
  width: 100vw;
  height: 100vh;
  color: #e5e7f0;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}
.homepage__refurbished-images {
  width: 100vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-height: 60%;
}
@media (max-width: 767.98px) {
  .homepage__refurbished-images {
    flex-direction: column;
    gap: 2em;
  }
}
.homepage__refurbished-image1 {
  max-height: 691px;
}
@media (max-width: 767.98px) {
  .homepage__refurbished-image1 {
    max-width: 40%;
  }
}
@media (min-width: 1090px) {
  .homepage__refurbished-image1 {
    scale: 1.35;
  }
}
.homepage__refurbished-image2 {
  max-height: 418px;
}
@media (max-width: 767.98px) {
  .homepage__refurbished-image2 {
    max-width: 60%;
    align-self: flex-end;
  }
}
.homepage__refurbished-subtitle {
  margin: 4.94rem 0 3.94rem 0;
}
.homepage__refurbished-title {
  margin: 2rem 0 2.25rem 0;
}
.homepage__refurbished-link {
  color: #e5e7f0;
  transition: transform 200ms ease-in-out;
}
.homepage__refurbished-link:hover, .homepage__refurbished-link:active {
  transform: translateY(-3px);
  color: #a520e1;
}
.homepage__pcparallax {
  height: 70vh;
  width: 100vw;
  display: flex;
  justify-content: center;
}
.homepage__pcparallax-container {
  margin: 3em 0;
  position: relative;
  width: 80%;
  height: 100%;
}
.homepage__pcparallax-pc1 {
  position: absolute;
  top: 30%;
  left: 20%;
  right: 0;
  z-index: 3;
}
@media (min-width: 2000px) {
  .homepage__pcparallax-pc1 {
    scale: 1.5;
  }
}
.homepage__pcparallax-pc2 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 2;
}
@media (min-width: 2000px) {
  .homepage__pcparallax-pc2 {
    scale: 1.5;
  }
}
.homepage__pcparallax-pc3 {
  position: absolute;
  top: -30%;
  right: 20%;
  z-index: 1;
}
@media (min-width: 2000px) {
  .homepage__pcparallax-pc3 {
    scale: 1.5;
  }
}
.homepage__about {
  z-index: 4;
  width: 95vw;
  background: #161616;
  min-height: 42rem;
  border-radius: 3.125rem;
  color: #e5e7f0;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}
.homepage__about-title {
  font-weight: 400;
  margin: 8.3rem 0 8.3rem 0;
}
.homepage__about-content {
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin-top: -3rem;
}
@media (max-width: 767.98px) {
  .homepage__about-content {
    flex-direction: column;
    align-items: center;
    margin-bottom: 3rem;
  }
}
.homepage__about-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30%;
}
@media (max-width: 767.98px) {
  .homepage__about-container {
    width: 80%;
  }
}
.homepage__about-subtitle {
  font-size: 1.375rem;
  font-weight: 400;
  color: #c85afa;
  margin-bottom: 2.69rem;
}
.homepage__about-text {
  font-size: 1rem;
  line-height: 1.5rem;
}
.homepage__stock {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 6.6rem;
  max-width: 100vw;
  overflow: hidden;
}
.homepage__stock-container {
  display: flex;
  justify-content: space-between;
  gap: 5em;
  text-align: left;
  margin: 3.5rem;
}
@media (max-width: 767.98px) {
  .homepage__stock-container {
    flex-direction: column;
    max-width: 100%;
  }
}
.homepage__stock-container-inverse {
  flex-direction: row-reverse;
}
@media (max-width: 767.98px) {
  .homepage__stock-container-inverse {
    flex-direction: column;
    max-width: 100%;
  }
}
.homepage__stock-title {
  font-weight: 400;
  margin: 5.62rem 0;
}
.homepage__stock-subtitle {
  font-size: 2rem;
  font-weight: 400;
  margin: 1.13rem 0;
}
.homepage__stock-description {
  max-width: 20rem;
  margin: 0 0 1.5rem 0;
}
.homepage__stock-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
@media (max-width: 767.98px) {
  .homepage__stock-text {
    text-align: center;
    align-items: center;
  }
}
.homepage__stock-text-inverse {
  align-items: flex-end;
  text-align: right;
}
@media (max-width: 767.98px) {
  .homepage__stock-text-inverse {
    text-align: center;
    align-items: center;
  }
}
.homepage__stock-custom {
  max-width: 42rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media (max-width: 767.98px) {
  .homepage__stock-custom {
    width: 80%;
  }
}
.homepage__stock-custom-description {
  margin: 1.44rem 0 1.06rem 0;
}
.homepage__stock-link {
  transition: all 200ms ease-in-out;
  justify-self: center;
  font-size: 1rem;
}
.homepage__stock-link:hover, .homepage__stock-link:active {
  transform: translateY(-3px);
  background: #a520e1;
  box-shadow: 0 0 5px #a520e1;
  color: white;
}
.homepage__stock-image {
  max-width: 100%;
}
.homepage__reviews {
  max-height: 820px;
  width: 100vw;
  background: #161616;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #e5e7f0;
  overflow: hidden;
}
@media (max-width: 1440px) {
  .homepage__reviews {
    max-height: fit-content;
  }
}
.homepage__reviews-title {
  font-weight: 400;
  margin: 5.63rem 0 1.65rem 0;
}
.homepage__reviews-slogan {
  margin: 0;
}
.homepage__reviews-container {
  display: flex;
  justify-content: center;
  gap: 4.25rem;
  margin: 5.6rem 0 6rem 0;
  width: 100%;
}
@media (max-width: 1440px) {
  .homepage__reviews-container {
    flex-direction: column;
    align-items: center;
    max-width: 100%;
  }
}
.homepage__realestate {
  margin: 4em 0;
  height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}
.homepage__realestate-title {
  font-weight: 400;
}
.homepage__realestate-logoContainer {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 2em;
}
@media (max-width: 1440px) {
  .homepage__realestate-logoContainer {
    flex-direction: column;
    max-width: 100%;
  }
}
.homepage__realestate-logo1, .homepage__realestate-logo2 {
  max-width: 430px;
  max-height: fit-content;
}
@media (max-width: 1440px) {
  .homepage__realestate-logo1, .homepage__realestate-logo2 {
    max-width: 340px;
  }
}

.shop {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}
.shop__hero {
  height: 60vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: relative;
}
.shop__hero-title {
  position: absolute;
  margin-bottom: 1em;
  font-size: 2.75rem;
  font-weight: 400;
  top: 60%;
}
@media (max-width: 767.98px) {
  .shop__hero-title {
    text-align: center;
  }
}
.shop__forSale-headerContainer {
  width: 90%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 2em;
}
.shop__forSale-title {
  color: rgba(72, 4, 148, 0.45);
  font-size: 2rem;
  font-weight: 400;
}
@media (max-width: 767.98px) {
  .shop__forSale-title {
    font-size: 1.333rem;
  }
}
.shop__forSale-decobar {
  border: solid rgba(72, 4, 148, 0.05) 1px;
  width: 70%;
  height: 0px;
}
.shop__forSale-pcList {
  width: 80%;
  display: grid;
  justify-items: center;
  grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));
  gap: 4em 2em;
  margin-bottom: 2em;
}
@media (max-width: 767.98px) {
  .shop__forSale-pcList {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    align-items: center;
  }
}
.shop__forSale-filters-button {
  background: rgba(71, 4, 148, 0.2039215686);
  color: white;
  cursor: pointer;
  border: none;
  border-radius: 20px;
  padding: 0.5em;
}
.shop__forSale-filters-button--active {
  background: rgba(71, 4, 148, 0.3921568627);
}
@media (max-width: 767.98px) {
  .shop__forSale-filters {
    max-width: 30%;
  }
}

.pcDetails {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.pcDetails__hero {
  width: 100vw;
  display: flex;
  gap: 6.3rem;
  align-items: center;
  justify-content: center;
  min-height: 80vh;
}
@media (max-width: 767.98px) {
  .pcDetails__hero {
    flex-direction: column;
    gap: 2em;
    margin: 2em 0;
    text-align: center;
  }
}
@media (max-width: 767.98px) {
  .pcDetails__hero-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
.pcDetails__hero-pcName {
  font-size: 2.75rem;
  font-weight: 400;
  margin: 1.5rem 0;
}
.pcDetails__hero-pcFullName {
  font-size: 2rem;
  font-weight: 400;
  margin: 0;
}
.pcDetails__hero-pcPrice {
  font-size: 1.3125rem;
  font-weight: 400;
  margin: 3rem 0 1.5rem 0;
}
.pcDetails__hero-pcBuy {
  width: 5.1875rem;
  height: 2.43369rem;
  border-radius: 1.25rem;
  color: #e5e7f0;
  font-size: 1.3125rem;
}
.pcDetails__specsBanner {
  height: 30vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pcDetails__specsBanner-title {
  font-family: "Josefin Sans";
  font-size: 2.75rem;
  font-weight: 400;
  text-decoration-line: underline;
}
.pcDetails__specs {
  display: grid;
  margin: 3em auto;
  width: 90%;
  justify-items: center;
  grid-template-columns: repeat(auto-fit, minmax(440px, 3fr));
  gap: 4em 2em;
}
@media (max-width: 767.98px) {
  .pcDetails__specs {
    display: flex;
    flex-direction: column;
  }
}
.pcDetails__specs-container {
  display: flex;
  align-items: center;
  gap: 1.3em;
  width: 100%;
}
@media (max-width: 767.98px) {
  .pcDetails__specs-container {
    flex-direction: column;
    text-align: center;
  }
}
.pcDetails__specs-text {
  width: 60%;
}
.pcDetails__specs-image {
  width: 9.3rem;
  height: 9.3rem;
}
.pcDetails__specs-title {
  font-size: 2rem;
  font-weight: 400;
  margin: 0.5em 0;
}
.pcDetails__specs-data {
  font-family: "Josefin Sans";
  font-size: 1.3125rem;
  font-weight: 400;
  margin: 0;
}

.login {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
}
.login__container {
  background: #161616;
  width: 40%;
  height: 40vh;
  border-radius: 2.5rem;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1em;
}
@media (max-width: 767.98px) {
  .login__input {
    max-width: 80%;
  }
}
.login__label {
  color: #e5e7f0;
}

.backoffice {
  min-height: 90vh;
}

.addPC {
  min-height: 90vh;
  margin: 4em 0;
}

.newPCForm {
  width: 100%;
  overflow: hidden;
}
.newPCForm__row {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 6.3rem;
}
@media (max-width: 767.98px) {
  .newPCForm__row {
    flex-direction: column;
    align-items: center;
  }
}
.newPCForm__row-last {
  width: 30%;
  margin: 2em auto;
  align-items: center;
}
@media (max-width: 767.98px) {
  .newPCForm__row-last {
    flex-direction: row;
    gap: 2em;
  }
}
.newPCForm__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.44rem;
}
@media (max-width: 767.98px) {
  .newPCForm__container {
    width: 100%;
    margin: 2em 0;
  }
}
.newPCForm__subtitle {
  color: #000;
  text-align: center;
  font-family: "Josefin Sans";
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.newPCForm__subtitle-fulldescript {
  margin-bottom: 3rem;
}
.newPCForm__input {
  border-radius: 0.625rem;
  border: 1px solid #000;
  background: #fdfdfd;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  width: 22.0625rem;
  height: 1.8125rem;
  flex-shrink: 0;
  padding-left: 1em;
}
.newPCForm__input-container {
  display: flex;
  gap: 1.88rem;
  justify-content: flex-end;
  align-items: center;
  width: 29.5rem;
}
@media (max-width: 1440px) {
  .newPCForm__input-container {
    flex-direction: column;
    width: 80%;
  }
}
@media (max-width: 767.98px) {
  .newPCForm__input {
    width: 80%;
  }
}
.newPCForm__image-container {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 2.5rem;
  background: #e8f1f6;
  width: 420px;
  height: 169px;
  flex-shrink: 0;
  margin-bottom: 1em;
}
@media (max-width: 767.98px) {
  .newPCForm__image-container {
    width: 80%;
  }
}
.newPCForm__coverImage-Preview {
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100%;
  opacity: 0;
  z-index: 2;
}
.newPCForm__coverImage-AddButton {
  width: 173px;
  height: 36px;
  border-radius: 50px;
  border: none;
  background: #cbd6dc;
  color: #306685;
  text-align: center;
  font-family: Work Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 1em;
}
.newPCForm__coverImage::file-selector-button {
  opacity: 0;
}
.newPCForm__coverImage-AddRules {
  display: flex;
  color: #444;
  text-align: center;
  font-family: Work Sans;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.newPCForm__coverImage-input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
}
.newPCForm__coverImage-input:hover, .newPCForm__coverImage-input:active {
  cursor: pointer;
}
.newPCForm__sold-label {
  font-size: 1.3125rem;
}
.newPCForm__sold-container {
  display: flex;
  gap: 0.63rem;
  align-items: center;
}
.newPCForm__submit {
  font-size: 1.3125rem;
}

input[type=checkbox] {
  display: grid;
  place-content: center;
  appearance: none;
  background-color: #d9d9d9;
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 1.15em;
  height: 1.15em;
  border-radius: 0.15em;
  transform: translateY(-0.075em);
  cursor: pointer;
}
input[type=checkbox]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em rgb(72, 4, 148);
}
input[type=checkbox]:checked::before {
  transform: scale(1);
}

.updatePC__feature {
  margin-top: 2em;
}
.updatePC__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.updatePC__deleteModal {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.updatePC__deleteModal-button-container {
  display: flex;
  gap: 1em;
}

.maintenance {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-family: "Josefin Sans";
  max-height: 100vh;
  background-color: #e9e0f2;
}
.maintenance__logo {
  max-height: 60vh;
  aspect-ratio: 1/1;
}
.maintenance__text {
  max-width: 60%;
}

