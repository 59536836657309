.homepage {
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 0;
  @media (max-width: $breakpoint-mobile) {
    overflow: hidden;
  }
  &__hero {
    height: 100vh;
    min-width: 100vw;
    background: $colour-secondary;
    overflow: hidden;
    @media (max-width: $breakpoint-mobile) {
      min-height: 100%;
      overflow: hidden;
    }
    &-video {
      min-width: 100%;
      overflow: hidden;
      object-fit: cover;
      @media (max-width: $breakpoint-mobile) {
        min-width: 0;
      }
    }
  }
  &__featured {
    height: 100vh;
    overflow: hidden;
    &-text {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
    &-new {
      margin: 4.94rem 0 3.94rem 0;
    }
    &-title {
      margin: 2rem 0 2.25rem 0;
    }
    &-learnmore {
      margin-top: 0.69rem;
      transition: transform 200ms ease-in-out;
      &:hover,
      &:active {
        transform: translateY(-3px);
        color: $colour-accent;
      }
    }
    &-buy {
      transition: all 200ms ease-in-out;
      font-size: 1rem;
      &:hover,
      &:active {
        transform: translateY(-3px);
        background: $colour-accent;
        box-shadow: $bs-colour-accent;
        color: white;
      }
    }
  }
  &__refurbished {
    background: $colour-secondary;
    z-index: 5;
    width: 100vw;
    height: 100vh;
    color: $colour-text-inverse;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    &-images {
      width: 100vw;
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-height: 60%;
      @media (max-width: $breakpoint-mobile) {
        flex-direction: column;
        gap: 2em;
      }
    }
    &-image1 {
      max-height: 691px;
      @media (max-width: $breakpoint-mobile) {
        max-width: 40%;
      }
      @media (min-width: 1090px) {
        scale: 1.35;
      }
    }
    &-image2 {
      max-height: 418px;
      @media (max-width: $breakpoint-mobile) {
        max-width: 60%;
        align-self: flex-end;
      }
    }
    &-subtitle {
      margin: 4.94rem 0 3.94rem 0;
    }
    &-title {
      margin: 2rem 0 2.25rem 0;
    }
    &-link {
      color: $colour-text-inverse;
      transition: transform 200ms ease-in-out;
      &:hover,
      &:active {
        transform: translateY(-3px);
        color: $colour-accent;
      }
    }
  }
  &__pcparallax {
    height: 70vh;
    width: 100vw;
    display: flex;
    justify-content: center;

    &-container {
      margin: 3em 0;
      position: relative;
      width: 80%;
      height: 100%;
    }
    &-pc1 {
      position: absolute;
      top: 30%;
      left: 20%;
      right: 0;
      z-index: 3;
      @media (min-width: 2000px) {
        scale: 1.5;
      }
    }
    &-pc2 {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      margin: 0 auto;
      z-index: 2;
      @media (min-width: 2000px) {
        scale: 1.5;
      }
    }
    &-pc3 {
      position: absolute;
      top: -30%;
      right: 20%;
      z-index: 1;
      @media (min-width: 2000px) {
        scale: 1.5;
      }
    }
  }
  &__about {
    z-index: 4;
    width: 95vw;
    background: $colour-secondary;
    min-height: 42rem;
    border-radius: 3.125rem;
    color: $colour-text-inverse;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    &-title {
      font-weight: 400;
      margin: 8.3rem 0 8.3rem 0;
    }
    &-content {
      display: flex;
      justify-content: space-between;
      width: 80%;
      margin-top: -3rem;
      @media (max-width: $breakpoint-mobile) {
        flex-direction: column;
        align-items: center;
        margin-bottom: 3rem;
      }
    }
    &-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 30%;
      @media (max-width: $breakpoint-mobile) {
        width: 80%;
      }
    }
    &-subtitle {
      font-size: 1.375rem;
      font-weight: 400;
      color: $colour-accent-light;
      margin-bottom: 2.69rem;
    }
    &-text {
      font-size: 1rem;
      line-height: 1.5rem;
    }
  }
  &__stock {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 6.6rem;
    max-width: 100vw;
    overflow: hidden;
    &-container {
      display: flex;
      justify-content: space-between;
      gap: 5em;
      text-align: left;
      margin: 3.5rem;
      @media (max-width: $breakpoint-mobile) {
        flex-direction: column;
        max-width: 100%;
      }
      &-inverse {
        flex-direction: row-reverse;
        @media (max-width: $breakpoint-mobile) {
          flex-direction: column;
          max-width: 100%;
        }
      }
    }
    &-title {
      font-weight: 400;
      margin: 5.62rem 0;
    }
    &-subtitle {
      font-size: 2rem;
      font-weight: 400;
      margin: 1.13rem 0;
    }
    &-description {
      max-width: 20rem;
      margin: 0 0 1.5rem 0;
    }
    &-text {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      @media (max-width: $breakpoint-mobile) {
        text-align: center;
        align-items: center;
      }
      &-inverse {
        align-items: flex-end;
        text-align: right;
        @media (max-width: $breakpoint-mobile) {
          text-align: center;
          align-items: center;
        }
      }
    }
    &-custom {
      max-width: 42rem;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      @media (max-width: $breakpoint-mobile) {
        width: 80%;
      }
      &-description {
        margin: 1.44rem 0 1.06rem 0;
      }
    }
    &-link {
      transition: all 200ms ease-in-out;
      justify-self: center;
      font-size: 1rem;
      &:hover,
      &:active {
        transform: translateY(-3px);
        background: $colour-accent;
        box-shadow: $bs-colour-accent;
        color: white;
      }
    }
    &-image {
      max-width: 100%;
    }
  }
  &__reviews {
    max-height: 820px;
    width: 100vw;
    background: $colour-secondary;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $colour-text-inverse;
    overflow: hidden;
    @media (max-width: $breakpoint-tablet) {
      max-height: fit-content;
    }
    &-title {
      font-weight: 400;
      margin: 5.63rem 0 1.65rem 0;
    }
    &-slogan {
      margin: 0;
    }
    &-container {
      display: flex;
      justify-content: center;
      gap: 4.25rem;
      margin: 5.6rem 0 6rem 0;
      width: 100%;
      @media (max-width: $breakpoint-tablet) {
        flex-direction: column;
        align-items: center;
        max-width: 100%;
      }
    }
  }
  &__realestate {
    margin: 4em 0;
    &-title {
      font-weight: 400;
    }
    height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    &-logoContainer {
      width: 80%;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      gap: 2em;
      @media (max-width: $breakpoint-tablet) {
        flex-direction: column;
        max-width: 100%;
      }
    }
    &-logo1,
    &-logo2 {
      max-width: 430px;
      max-height: fit-content;
      @media (max-width: $breakpoint-tablet) {
        max-width: 340px;
      }
    }
  }
}
